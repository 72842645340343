import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled, {createGlobalStyle} from "styled-components"
import { graphql } from 'gatsby';
import Nav from '../components/Nav'
import TuoteLista from '../components/TuoteLista'
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.white ? '#FFFFFF' : '#F0F0F0')};
  }
`
const Kehys = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 40px;
padding-right: 40px;
height: auto;
`
const KehysM = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 20px;
padding-right: 20px;
height: auto;
`
const SisempiKehys = styled.div`
height: auto;
padding-bottom: 20px;
`
const Otsikko = styled.h1`
padding-top: 0px;
margin: 0px;
font-weight: bold;
font-size: 16px;
`
const Desktop = styled.div`
width: 1260px;
background:white;
height: 100;
`
const White = styled.div`
background-color:'white';
`
const Responsive = styled.div`
display:grid;
grid-template-columns: 1fr 1260px 1fr;
height: 100vh;
margin:0;
padding:0;
`
const BackGround = styled.div`
background-color: #F0F0F0;
z-index: -1000;
height: auto;
`


const Esittely = ({ teksti }) => {
  return (
    <SisempiKehys>
      <Otsikko>Metaka-koukkulavavaunut</Otsikko>
      <p>{teksti}</p>
    </SisempiKehys>
  )
}

function MobileIndex({ tuotteet, teksti }) {
  return (
    <Fragment>
      <GlobalStyle white />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka - Koukkulavavaunut</title>
      </Helmet>
      <Nav />
      <StaticImage
        alt="teksti"
        src="../assets/metaka_etusivu.jpg"
        style={{
          gridArea: "1/1",
          maxHeight: 700,
          marginTop: '64px'
        }}
        layout="fullWidth"
        objectFit='cover'
      />
      <KehysM>
        <Esittely teksti={teksti} />
        <TuoteLista tuotteet={tuotteet} />
      </KehysM>
    </Fragment>
  )
}
function TabletIndex({ tuotteet, teksti }) {
  return (
    <Fragment>
      <GlobalStyle white />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka - Koukkulavavaunut</title>
      </Helmet>
      <Nav />
      <StaticImage
        alt="teksti"
        src="../assets/metaka_etusivu.jpg"
        style={{
          gridArea: "1/1",
          maxHeight: 700,
          marginTop: '64px'
        }}
        layout="fullWidth"
        objectFit='cover'
      />
      <KehysM>
        <Esittely teksti={teksti} />
        <TuoteLista tablet tuotteet={tuotteet} />
      </KehysM>
    </Fragment>
  )
}

function DesktopIndex({ tuotteet, teksti}) {
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka - Koukkulavavaunut</title>
      </Helmet>
      <Responsive>
        <BackGround />
        <Desktop>
          <Nav />
          <StaticImage
            alt="teksti"
            src="../assets/metaka_etusivu.jpg"
            style={{
              gridArea: "1/1",
              maxHeight: 600,
              marginTop: '64px',
            }}
            layout="fullWidth"
            objectFit='cover'
          />
          <Kehys>
            <Esittely teksti={teksti} />
            <TuoteLista desktop tuotteet={tuotteet} />
          </Kehys>
        </Desktop>
        <BackGround />
      </Responsive>
    </Fragment>
  )
}

function IndexPage({ data }) {
  const breakpoints = useBreakpoint();
  const tuotteet = data.allSanityTuotteet.nodes;
  const teksti = data.allSanityEtusivu.nodes[0].etusivun_teksti;
  return (
    <div>
      {breakpoints.sm ? <MobileIndex tuotteet={tuotteet} teksti={teksti} /> : breakpoints.md ? <TabletIndex tuotteet={tuotteet} teksti={teksti} /> : <DesktopIndex tuotteet={tuotteet} teksti={teksti} />}
    </div>
  )
}

export default IndexPage

export const query = graphql`
query Tuotteet {
  allSanityEtusivu {
    nodes {
      etusivun_teksti
    }
  }
  allSanityTuotteet {
    nodes {
      tuotteen_nimi
      telipaino
      rungon_pituus
      rungon_korkeus_maasta
      rengaskoko
      omapaino
      lisavarusteet
      lavan_pituudet
      korkeus_lavan_pohjaan_maasta
      kokonaispaino
      kippauskulma
      kantavuus
      akselien_maara
      aisapaino
      tuotteen_lyhyt_esittely
      tuotteen_esittelyteksti
      tuotteen_kuvat {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
}
`



